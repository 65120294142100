











































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { OrderReportsListItemType } from '@/types/orderReports'
import InputCommon from '@/components/common/InputCommon.vue'
import DatePickerCommon from '@/components/common/DatePickerCommon.vue'
@Component({
  components: { DatePickerCommon, InputCommon },
})
export default class OrdersTable extends Vue {
  @Prop({ type: Boolean })
  loading!: boolean

  @Prop({ type: Array })
  orders!: OrderReportsListItemType

  idFilter = ''
  orderNumberFilter = ''
  priceFilter = ''
  orderDateFilter = ''

  handleAddFilter(data: string, target: string): void {
    this.$emit('add-filter', { data, target })
  }

  handleChangeOrder(data: object): void {
    this.$emit('change-sort', data)
  }
}
