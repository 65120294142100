






















import { Component, Vue, Prop } from 'vue-property-decorator'
import { AccountsListItemType } from '@/types/accounts'

@Component
export default class AccountsCard extends Vue {
  @Prop({ type: Object })
  account!: AccountsListItemType
}
