















































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AccountsListItemType } from '@/types/accounts'
import InputCommon from '@/components/common/InputCommon.vue'
@Component({
  components: { InputCommon },
})
export default class AccountsTable extends Vue {
  @Prop({ type: Boolean })
  loading?: boolean

  @Prop({ type: Array })
  accounts!: AccountsListItemType[]

  idFilter = ''
  balanceFilter = ''

  handleAddFilter(data: string, target: string): void {
    this.$emit('add-filter', { data, target })
  }

  handleChangeOrder(data: object): void {
    this.$emit('change-sort', data)
  }
}
