
































import { Component, Vue, Prop } from 'vue-property-decorator'
import { CodesListItemType } from '@/types/codes'

@Component
export default class CodesCard extends Vue {
  @Prop({ type: Object })
  code!: CodesListItemType
}
