


















































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { MembersListItemType } from '@/types/members'
import {
  TableExportType,
  TableFilterType,
  TableOrderType,
} from '@/types/general'
import CodesTable from '@/components/member_detail/CodesTable.vue'
import { CodesListItemType } from '@/types/codes'
import FilterTags from '@/components/common/FilterTags.vue'
import { AccountsListItemType } from '@/types/accounts'
import AccountsTable from '@/components/member_detail/AccountsTable.vue'
import { OrdersListItemType } from '@/types/orders'
import OrdersTable from '@/components/member_detail/OrdersTable.vue'
import TabsCommon from '@/components/common/TabsCommon.vue'
import CodesCard from '@/components/mobile/member_detail/CodesCard.vue'
import Drawer from '@/components/common/Drawer.vue'
import CodesFilters from '@/components/mobile/member_detail/CodesFilters.vue'
import AccountsCard from '@/components/mobile/member_detail/AccountsCard.vue'
import AccountsFilters from '@/components/mobile/member_detail/AccountsFilters.vue'
import OrdersCard from '@/components/mobile/member_detail/OrdersCard.vue'
import OrdersFilters from '@/components/mobile/member_detail/OrdersFilters.vue'

@Component({
  components: {
    OrdersFilters,
    OrdersCard,
    AccountsFilters,
    AccountsCard,
    CodesFilters,
    Drawer,
    CodesCard,
    TabsCommon,
    OrdersTable,
    AccountsTable,
    FilterTags,
    CodesTable,
  },
})
export default class MemberDetail extends Vue {
  codesLoading = false
  accountsLoading = false
  ordersLoading = false

  showCodesFilters = false
  codesFilters: TableFilterType[] = []
  codesOrder: TableOrderType | Record<string, any> = {}
  codesPage = 1
  codesFilterNames = {
    ARTNUMBER: 'Артикул',
    PREFIX: 'Префикс',
    POINTS: 'Баллы',
    BONUS: 'Бонус',
    CODE: 'Код',
  }

  showAccountsFilters = false
  accountsFilters: TableFilterType[] = []
  accountsOrder: TableOrderType | Record<string, any> = {}
  accountsPage = 1
  accountsFilterNames = {
    ID: 'Id',
    BALANCE: 'Активный баланс',
  }

  showOrdersFilters = false
  ordersFilters: TableFilterType[] = []
  ordersSorting: TableOrderType | Record<string, any> = {}
  ordersPage = 1
  ordersFilterNames = {
    ID: 'Id',
    UF_EXT_ID: 'Номер заказа',
    '>=UF_DATE_ORDER': 'Дата заказа',
    UF_ORDER_TOTAL: 'Стоимость',
  }

  tabs = ['Активированные коды', 'Счета', 'Заказы призов']

  get member(): MembersListItemType {
    return this.$store.getters['members/membersDetail']
  }

  get memberExport(): TableExportType {
    return this.$store.getters['members/exportMember']
  }

  //Активированные коды
  get codesParams(): object {
    const params = {
      filter: {
        USER_LOGIN: this.member.UF_REMOTE_USER_LOGIN,
      },
      order: {},
      offset: {},
    }

    this.codesFilters.forEach((data) => {
      params.filter[data.target] = data.data
    })

    if (this.codesOrder && this.codesOrder.order) {
      params.order[this.codesOrder.prop] =
        this.codesOrder.order === 'descending' ? 'DESC' : 'ASC'
    }

    params.offset = (this.codesPage - 1) * 10

    return params
  }

  get activatedCodes(): CodesListItemType[] {
    return this.$store.getters['codes/codesList']
  }

  get codesTotal(): string {
    return this.$store.getters['codes/codesTotal']
  }

  //Счета
  get accountsParams(): object {
    const params = {
      filter: {
        UF_USER_ID: this.member.ID,
      },
      order: {},
      offset: {},
    }

    this.accountsFilters.forEach((data) => {
      params.filter[data.target] = data.data
    })

    if (this.accountsOrder && this.accountsOrder.order) {
      params.order[this.accountsOrder.prop] =
        this.accountsOrder.order === 'descending' ? 'DESC' : 'ASC'
    }

    params.offset = (this.accountsPage - 1) * 10

    return params
  }

  get accounts(): AccountsListItemType[] {
    return this.$store.getters['accounts/accountsList']
  }

  get accountsTotal(): string {
    return this.$store.getters['accounts/accountsTotal']
  }

  //Заказы призов
  get ordersParams(): object {
    const params = {
      filter: {
        UF_USER_ID: this.member.ID,
      },
      order: {},
      offset: {},
    }

    this.ordersFilters.forEach((data) => {
      params.filter[data.target] = data.data
    })

    if (this.ordersSorting && this.ordersSorting.order) {
      params.order[this.ordersSorting.prop] =
        this.ordersSorting.order === 'descending' ? 'DESC' : 'ASC'
    }

    params.offset = (this.ordersPage - 1) * 10

    return params
  }

  get orders(): OrdersListItemType[] {
    return this.$store.getters['orders/ordersList']
  }

  get ordersTotal(): string {
    return this.$store.getters['orders/ordersTotal']
  }

  //Активированные коды
  async changeCodesTable(): Promise<void> {
    const tabs = document.querySelector('[data-scroll="codes"]') as HTMLElement
    tabs.scrollIntoView({ behavior: 'smooth' })

    this.codesLoading = true

    await this.$store.dispatch('codes/getCodeRequestList', this.codesParams)

    this.codesLoading = false
  }

  handleAddTableCodesFilter(data: TableFilterType): void {
    this.codesFilters.push({
      data: data.data,
      target: data.target,
      name: this.codesFilterNames[data.target],
    })
  }

  handleDeleteCodesFilter(index: number): void {
    this.codesFilters.splice(index, 1)
  }

  handleChangeCodesSort(data: TableOrderType): void {
    this.codesOrder = data
    this.changeCodesTable()
  }

  handleShowCodesFilters(): void {
    this.showCodesFilters = !this.showCodesFilters
  }

  handleSelectCodesFilters(data: TableFilterType[]): void {
    this.handleShowCodesFilters()
    data.forEach((value) => {
      this.handleAddTableCodesFilter(value)
    })
  }

  //Счета
  async changeAccountsTable(): Promise<void> {
    const tabs = document.querySelector(
      '[data-scroll="accounts"]'
    ) as HTMLElement
    tabs.scrollIntoView({ behavior: 'smooth' })

    this.accountsLoading = true

    await this.$store.dispatch('accounts/getAccountsList', this.accountsParams)

    this.accountsLoading = false
  }

  handleAddTableAccountsFilter(data: TableFilterType): void {
    this.accountsFilters.push({
      data: data.data,
      target: data.target,
      name: this.accountsFilterNames[data.target],
    })
  }

  handleDeleteAccountsFilter(index: number): void {
    this.accountsFilters.splice(index, 1)
  }

  handleChangeAccountsSort(data: TableOrderType): void {
    this.accountsOrder = data
    this.changeAccountsTable()
  }

  handleShowAccountsFilters(): void {
    this.showAccountsFilters = !this.showAccountsFilters
  }

  handleSelectAccountsFilters(data: TableFilterType[]): void {
    this.handleShowAccountsFilters()
    data.forEach((value) => {
      this.handleAddTableAccountsFilter(value)
    })
  }

  //Заказы призов
  async changeOrdersTable(): Promise<void> {
    const tabs = document.querySelector('[data-scroll="orders"]') as HTMLElement
    tabs.scrollIntoView({ behavior: 'smooth' })

    this.ordersLoading = true

    await this.$store.dispatch('orders/getOrdersList', this.ordersParams)

    this.ordersLoading = false
  }

  handleAddTableOrdersFilter(data: TableFilterType): void {
    this.ordersFilters.push({
      data: data.data,
      target: data.target,
      name: this.ordersFilterNames[data.target],
    })
  }

  handleDeleteOrdersFilter(index: number): void {
    this.ordersFilters.splice(index, 1)
  }

  handleChangeOrdersSort(data: TableOrderType): void {
    this.ordersSorting = data
    this.changeOrdersTable()
  }

  handleShowOrdersFilters(): void {
    this.showOrdersFilters = !this.showOrdersFilters
  }

  handleSelectOrdersFilters(data: TableFilterType[]): void {
    this.handleShowOrdersFilters()
    data.forEach((value) => {
      this.handleAddTableOrdersFilter(value)
    })
  }

  async handleExportMember(): Promise<void> {
    await this.$store.dispatch('members/getExportMember', this.member.ID)

    const link = document.createElement('a')

    link.setAttribute(
      'href',
      process.env.VUE_APP_BASE_URI + this.memberExport.link
    )
    link.setAttribute('download', 'download')
    link.click()
    link.remove()
  }

  setShowedCount(total: string, page: number): string | number {
    if (total) {
      return 10 * page > parseInt(total) ? total : 10 * page
    } else {
      return 0
    }
  }

  //Активированные коды
  @Watch('codesFilters')
  onCodesFiltersChange(): void {
    this.changeCodesTable()
  }

  @Watch('codesPage')
  onCodesPageChange(): void {
    this.changeCodesTable()
  }

  //Счета
  @Watch('accountsFilters')
  onAccountsFiltersChange(): void {
    this.changeAccountsTable()
  }

  @Watch('accountsPage')
  onAccountsPageChange(): void {
    this.changeAccountsTable()
  }

  //Заказы призов
  @Watch('ordersFilters')
  onOrdersFiltersChange(): void {
    this.changeOrdersTable()
  }

  @Watch('ordersPage')
  onOrdersPageChange(): void {
    this.changeOrdersTable()
  }

  async mounted(): Promise<void> {
    this.codesLoading = true
    this.accountsLoading = true
    this.ordersLoading = true

    await this.$store.dispatch(
      'members/getMembersDetail',
      this.$route.params.memberId
    )
    await this.$store.dispatch('codes/getCodeRequestList', this.codesParams)
    await this.$store.dispatch('accounts/getAccountsList', this.accountsParams)
    await this.$store.dispatch('orders/getOrdersList', this.ordersParams)

    this.codesLoading = false
    this.accountsLoading = false
    this.ordersLoading = false
  }
}
