


























































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { CodesListItemType } from '@/types/codes'
import InputCommon from '@/components/common/InputCommon.vue'
@Component({
  components: { InputCommon },
})
export default class CodesTable extends Vue {
  @Prop({ type: Boolean })
  loading?: boolean

  @Prop({ type: Array })
  codes!: CodesListItemType[]

  articleFilterText = ''
  prefixFilterText = ''
  scoreFilterText = ''
  codeFilterText = ''

  setTableRowStatus(dateBlock: string | null, state: string): string {
    if (dateBlock !== null || state !== 'ACTIVATED') {
      return 'color-dark'
    } else {
      return 'color-gray'
    }
  }

  handleAddFilter(data: string, target: string): void {
    this.$emit('add-filter', { data, target })
  }

  handleChangeOrder(data: object): void {
    this.$emit('change-sort', data)
  }
}
